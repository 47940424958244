<template>
    <modal ref="modalDuplicarComision" titulo="Duplicar comisión" :height="40" no-cancelar cerrar @guardar="duplicarCondicionCedis">
        <div class="row mx-0 justify-center">
            <div class="col-8 text-center text-general f-15 mb-2 ">
                Selecciona el cedis al cual se duplicará esta configuración
            </div>
            <div class="col-8 px-4 mb-3 mt-2">
                <el-checkbox v-model="radio" class="check-dark">
                    Cambiar condición por defecto a los {{$config.vendedor}}
                </el-checkbox>
            </div>
            <div class="col-8 text-general f-17 pl-4 f-600 mb-2 ">
                Cedis
            </div>
            <div v-for="(data,d) in cedis" :key="d" class="col-8 px-4 mb-3 mt-2">
                <el-checkbox v-model="data.checked" class="check-dark">
                    {{ data.nombre }}
                </el-checkbox>
            </div>
        </div>
    </modal>
</template>

<script>
import Condiciones from "~/services/configurar/admin/condiciones";
export default {
    data(){
        return {
            checked: false,
            cedis: [],
            id_cedis: null,
            radio: false
        }
    },
    methods: {
        toggle(cedis, idCedis){
            this.cedis = _.cloneDeep(cedis)
            this.id_cedis = idCedis
            this.$refs.modalDuplicarComision.toggle();
        },
        async duplicarCondicionCedis(){
            try {

                let cedis = this.cedis.filter(d => d.checked).map(({id}) => id)
                let params = {
                    cedis,
                    id_cedis: this.id_cedis,
                    leechero: this.radio
                }
                const {data} = await Condiciones.duplicarCondicionCedis(params)
                this.notificacion('', data.mensaje, 'success')
                this.$refs.modalDuplicarComision.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style>

</style>
